import Image_1 from "../images/Tarot-reader-1.jpg";
import Button from "./Button";

const Introduction = ({ home, buttonScroll }) => {
    return (
        <div ref={home} className="mt-[4%] pb-[5%]">
            <div className="lg:flex items-start mx-0">
                <div className="lg:w-3/5">
                    <div className="ml-[2%] mr-[2%] sm:ml-[10%] lg:ml-[15%] sm:m-auto">
                        <p className="mx-[10%] sm:mx-0 mt-[2%] font-bold text-center sm:text-left text-2xl md:text-3xl lg:text-4xl sm:mr-[10%] leading-8 lg:leading-10 mb-[4%]">
                            Illuminating life paths by shedding light on hidden
                            truth.
                        </p>
                        <p className="lg:mt-[10%] text-justify leading-8 md:leading-10 opacity-75 text-lg md:text-xl mb-[2%] sm:mb-[4%] mx-[4%] sm:mx-0 sm:mr-[15%]">
                            Unlock mysteries with Shivangi's Tarot Readings and
                            discover the ancient art of tarot with
                            DivineReadings. My experienced readings offer
                            personalized and intuitive insights to help you
                            navigate life's challenges and uncover your true
                            path.
                        </p>
                    </div>
                    <div className="lg:mt-[16%] text-center md:text-left md:ml-[10%] lg:ml-[15%]">
                        <Button
                            buttonScroll={buttonScroll}
                            width="w-3/5 sm:w-1/2"
                        />
                    </div>
                </div>
                <div className="flex justify-center lg:w-2/5">
                    <img
                        className="mt-[10%] lg:mt-0 lg:mr-[22%] h-[21rem] w-[17rem] md:h-[34rem] md:w-[26rem] lg:h-[38rem] lg:w-[29rem] rounded-[1rem]"
                        src={Image_1}
                        alt="tarot-reader"
                    />
                </div>
            </div>
        </div>
    );
};

export default Introduction;
