import Button from "./Button";
import Image_2 from "../images/Tarot-reader-2.jpg";

const Reviews = ({ buttonScroll, knowMe }) => {
    return (
        <div ref={knowMe} className="md:pb-[2%] lg:my-0 lg:flex">
            <div className="lg:w-3/5 lg:ml-[9%]">
                <img
                    className="m-auto lg:m-0 mt-[10%] lg:mt-0 lg:mr-[22%] h-[21rem] w-[17rem] md:h-[34rem] md:w-[26rem] lg:h-[36rem] lg:w-[28rem] rounded-[1rem]"
                    src={Image_2}
                    alt="tarot-reader"
                />
            </div>
            <div className="lg:w-2/5 text-center lg:text-left m-auto md:ml-[6%] md:mr-[5%]">
                <div className="text-[#212121] leading-8">
                    <p className="font-bold mt-[5%] sm:mt-[8%] lg:mt-0 sm:mb-[1%] md:mb-[2%] text-2xl md:text-3xl lg:text-4xl">
                        7+ years
                    </p>
                    <p className="text-lg md:text-xl">
                        Experience in Psychic Medium
                    </p>
                </div>
                <div className="lg:w-3/5 m-auto mt-[5%] md:mt-[6%] lg:my-[20%] text-[#212121] leading-8">
                    <p className="font-bold sm-mb-[1%] md:mb-[2%] text-2xl md:text-3xl lg:text-4xl">
                        50k+
                    </p>
                    <p className="text-lg md:text-xl">Satisfied Clients</p>
                </div>
                <div className="md:mt-[5%] lg:mt-[8%]">
                    <Button
                        buttonScroll={buttonScroll}
                        width="w-3/5 sm:w-1/2 lg:w-3/4"
                        name="Request Appointment"
                    />
                </div>
            </div>
        </div>
    );
};

export default Reviews;
