import Image_1 from "../images/Tarot-reader-3.jpeg";

const About = () => {
    return (
        <div className="py-[6%]">
            <div className="lg:flex">
                <div className="lg:w-3/5">
                    <div className="font-bold text-2xl md:text-3xl lg:text-4xl md:mr-[5%] lg:ml-[15%] mt-[3%] text-[#212121] lg:pb-0 leading-8 sm:leading-10">
                        <p className="mx-[6%] lg:mx-0 text-center md:text-left">
                            About Me
                        </p>
                    </div>
                    <div className="lg:pt-[10%] lg:ml-[8%] xl:ml-[16%] lg:mr-[8.5%] mt-[2%] text-[#212121]">
                        <p className="mx-[6%] text-justify pb-[4%] md:pb-0 leading-8 md:leading-10 text-lg md:text-xl">
                            I have been drawn to divination processes and mystic
                            tools since I was 7 years old and eventually
                            discovered my gift of insight and clairvoyance. I
                            decided to share this gift with the world to proceed
                            forward in their life with joy and peace. I have
                            been doing tarot readings, healings and divination
                            procedures for others since last 7 years helping
                            people to move on to better things in life.
                        </p>
                    </div>
                </div>
                <div className="lg:w-2/5 flex justify-center">
                    <img
                        className="m-auto mt-[10%] lg:mt-0 lg:mr-[22%] h-[21rem] w-[17rem] md:h-[34rem] md:w-[26rem] lg:h-[36rem] lg:w-[29rem] rounded-[1rem]"
                        src={Image_1}
                        alt="tarot-reader"
                    />
                </div>
            </div>
        </div>
    );
};

export default About;
