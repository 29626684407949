import React from "react";

const Testimonial = ({ headerText, bodyText, star }) => {
    return (
        <div className="text-justify min-w-250px overflow-y-auto w-[20rem] sm:w-[23rem] flex-shrink-0 p-6 rounded-2xl shadow-2xl shadow-black/50">
            <p className="text-lg sm:text-2xl mb-[5%]">{headerText}</p>
            <p className="opacity-75 text-sm md:text-lg">{bodyText}</p>
            <p className="opacity-75 text-xl pt-[5%] pb-0">{star}</p>
        </div>
    );
};

export default Testimonial;
