import Logo from "../images/logo.jpg";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = ({ home, knowMe, testimonials, services, contactMe }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = (scrollToFunction) => {
        scrollToFunction();
        setIsOpen(false);
    };

    const homeClick = () => {
        window.scrollTo({ top: home.current.offsetTop, behavior: "smooth" });
    };

    const knowMeClick = () => {
        window.scrollTo({ top: knowMe.current.offsetTop, behavior: "smooth" });
    };

    const testimonialsClick = () => {
        window.scrollTo({
            top: testimonials.current.offsetTop,
            behavior: "smooth",
        });
    };

    const servicesClick = () => {
        window.scrollTo({
            top: services.current.offsetTop,
            behavior: "smooth",
        });
    };

    const contactMeClick = () => {
        window.scrollTo({
            top: contactMe.current.offsetTop,
            behavior: "smooth",
        });
    };

    return (
        <div className="z-10 left-0 w-full">
            <nav>
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <Link
                        className="self-center text-2xl font-semibold whitespace-nowrap flex items-center"
                        to="/">
                        <img
                            src={Logo}
                            alt="DivineReadings"
                            className="w-8 h-8 md:w-10 md:h-10 mr-2 rounded"
                        />
                        DivineReadings
                    </Link>

                    <div>
                        <button
                            onClick={toggleNavbar}
                            type="button"
                            className="inline-flex items-center p-2 w-12 h-12 justify-center text-sm rounded-lg md:hidden focus:outline-none"
                            aria-controls="navbar-default"
                            aria-expanded={isOpen}>
                            <span className="sr-only">
                                {isOpen ? "Close main menu" : "Open main menu"}
                            </span>
                            {isOpen ? (
                                // Cross icon for close
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                // Hamburger icon for open
                                <svg
                                    className="w-5 h-4"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 17 14">
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M1 1h15M1 7h15M1 13h15"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>

                    <div
                        className={`${
                            isOpen ? "" : "hidden"
                        } w-full md:block md:w-auto`}
                        id="navbar-default">
                        <ul className="opacity-90 bg-black rounded-lg md:bg-inherit font-medium flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
                            <Link
                                className="text-white md:text-black text-lg group transition-all duration-300 ease-in-out block py-2 px-3 md:px-3 rounded md:hover:bg-transparent md:border-0 md:p-0"
                                to="/">
                                <li
                                    className="md:py-2 bg-left-bottom bg-gradient-to-r md:from-black md:to-black bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-in-out"
                                    onClick={() => handleLinkClick(homeClick)}>
                                    Home
                                </li>
                            </Link>
                            <Link
                                className="text-white md:text-black text-lg group transition-all duration-300 ease-in-out block py-2 px-3 md:px-3 rounded md:hover:bg-transparent md:border-0 md:p-0"
                                to="/know">
                                <li
                                    className="md:py-2 bg-left-bottom bg-gradient-to-r md:from-black md:to-black bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-in-out"
                                    onClick={() =>
                                        handleLinkClick(knowMeClick)
                                    }>
                                    About Me
                                </li>
                            </Link>
                            <Link
                                className="text-white md:text-black text-lg group transition-all duration-300 ease-in-out block py-2 px-3 md:px-3 rounded md:hover:bg-transparent md:border-0 md:p-0"
                                to="/services">
                                <li
                                    className="md:py-2 bg-left-bottom bg-gradient-to-r md:from-black md:to-black bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-in-out"
                                    onClick={() =>
                                        handleLinkClick(servicesClick)
                                    }>
                                    Services
                                </li>
                            </Link>
                            <Link
                                className="text-white md:text-black text-lg group transition-all duration-300 ease-in-out block py-2 px-3 md:px-3 rounded md:hover:bg-transparent md:border-0 md:p-0"
                                to="/testimonials">
                                <li
                                    className="md:py-2 bg-left-bottom bg-gradient-to-r md:from-black md:to-black bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-in-out"
                                    onClick={() =>
                                        handleLinkClick(testimonialsClick)
                                    }>
                                    Testimonials
                                </li>
                            </Link>
                            <Link
                                className="text-white md:text-black text-lg group transition-all duration-300 ease-in-out block py-2 px-3 md:px-3 rounded md:hover:bg-transparent md:border-0 md:p-0"
                                to="/contact">
                                <li
                                    className="md:py-2 bg-left-bottom bg-gradient-to-r md:from-black md:to-black bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-in-out"
                                    onClick={() =>
                                        handleLinkClick(contactMeClick)
                                    }>
                                    Contact
                                </li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;
