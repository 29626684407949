import { useState } from "react";

const Accordian = ({ headerText, bodyText }) => {
    const [showItems, setShowItems] = useState(false);

    const handleClick = () => {
        setShowItems(!showItems);
    };

    return (
        <div className="mt-[4%] md:mt-[1.5%]">
            <div
                onClick={handleClick}
                className="hover:cursor-pointer shadow-2xl rounded-[4rem] w-4/5 lg:w-3/5 m-auto px-[6%] md:px-[2.5%] py-[1.5%]">
                <div className="flex justify-between text-lg md:text-xl items-center">
                    <p className="md:mr-0 mr-[5%]">{headerText}</p>
                    <span>
                        {!showItems ? (
                            <span className="text-2xl md:text-4xl">+</span>
                        ) : (
                            <span className="text-2xl md:text-4xl">x</span>
                        )}
                    </span>
                </div>
            </div>
            {showItems && (
                <div className="w-8/12 text-sm md:text-lg md:w-4/5 lg:w-3/5 m-auto md:px-[2.5%] py-[1.5%]">
                    {showItems ? (
                        <p className="text-[#212121] opacity-75">{bodyText}</p>
                    ) : (
                        ""
                    )}
                </div>
            )}
        </div>
    );
};

export default Accordian;
