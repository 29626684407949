import Image_2 from "../images/appointment.jpeg";
import Button from "./Button";

const Appointment = ({ buttonScroll }) => {
    return (
        <div className="mb-[5%] lg:mb-0 lg:flex lg:ml-[7%]">
            <div className="lg:w-1/2 ml-[2%]">
                <img
                    className="m-auto lg:m-0 h-[20rem] w-[20rem] sm:h-[30rem] sm:w-[32rem] md:h-[35rem] md:w-[38rem] lg:w-[37.4rem] lg:h-[37.4rem]"
                    style={{
                        borderRadius: "18em 10000px 10000px 18em",
                    }}
                    src={Image_2}
                    alt="appointment"
                />
            </div>
            <div className="lg:w-1/2 m-auto md:ml-[10%] md:mr-[5%]">
                <div className="md:w-4/5 text-center md:text-left">
                    <p className="mx-[6%] sm:mx-0 text-center md:text-justify mt-[5%] lg:mt-0 font-bold text-2xl md:text-3xl lg:text-4xl text-[#212121] leading-8 sm:leading-10">
                        Ready to give a better life another shot ?
                    </p>

                    <p className="text-justify leading-7 lg:leading-10 text-lg md:text-xl my-[4%] lg:mt-[15%] w-10/12 m-auto lg:mr-[15%]">
                        My mission is to provide insightful and compassionate
                        tarot readings that empower my clients to make informed
                        decisions and live their best lives.
                    </p>
                    <div className="sm:mt-[5%] lg:mt-[15%]">
                        <Button
                            buttonScroll={buttonScroll}
                            width="w-3/5 sm:w-1/2 lg:w-3/4"
                            name="Request Appointment"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Appointment;
