import Accordian from "./Accordian";

const FAQSection = () => {
    return (
        <div className="my-[4%] lg:my-0 pb-[2%]">
            <div className="lg:w-3/4">
                <p className="mx-[8%] sm:mx-0 font-bold text-2xl md:text-3xl lg:text-4xl text-center lg:text-left mb-[2%] lg:ml-[12%]">
                    Need to know more? We've got you covered.
                </p>
                <p className="w-3/4 m-auto md:w-full md:mb-[4%] lg:mb-0 lg:ml-[27%] text-center lg:text-left lg:mt-[5%] text-lg md:text-xl leading-7">
                    A list of important and often asked questions. Have another
                    question not listed?
                </p>
            </div>
            <Accordian
                headerText="What is tarot reading?"
                bodyText="Tarot reading is a form of divination using a deck of tarot cards to gain insights into past, present, and future events. It involves interpreting the symbolism and imagery on the cards to provide guidance and answers to your questions."
            />
            <Accordian
                headerText="How do I book a tarot reading?"
                bodyText="You can book a tarot reading through our website. Click the Book an Appointment button, choose your preferred meeting time, select date and time, and complete the payment process. You will receive a confirmation email with all the details."
            />
            <Accordian
                headerText="What types of tarot readings do you offer?"
                bodyText="We offer a variety of tarot readings including in-person, online (via video call), and email readings. We also have specialty readings focusing on areas such as love and relationships, career and finance, and spiritual guidance."
            />
            <Accordian
                headerText="How long does a tarot reading session last?"
                bodyText="The length of a tarot reading session can vary. Typically, readings range from 30 minutes to an hour, depending on the type of reading and the complexity of your questions."
            />
        </div>
    );
};

export default FAQSection;
