import Footer from "./Footer";

const Contact = ({ top, testimonials, services, contactMe }) => {
    return (
        <div ref={contactMe}>
            <div className="container px-6 pt-4 lg:pb-20 mx-auto">
                <div className="lg:flex lg:items-center lg:-mx-6">
                    <div className="lg:w-1/2 lg:mx-6">
                        <div className="md:w-3/4 m-auto">
                            <div className="pb-8 lg:py-14">
                                <div className="font-bold text-center lg:hidden text-2xl md:text-3xl lg:text-4xl md:mb-6">
                                    <p>How to get in Touch</p>
                                </div>
                                <div className="lg:ml-[-18%] pb-[6%] hidden lg:block">
                                    <div className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
                                        <p>How to get in Touch</p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-justify leading-7 lg:leading-10 text-lg md:text-xl">
                                To request more information, schedule an
                                appointment, inquire about supervision, or any
                                other general questions you may have, please
                                fill out the form and I'll respond as soon as
                                possible.
                            </div>
                            <div className="mt-6 md:mt-16">
                                <p className="flex items-start -mx-2 py-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6 mx-2 text-blue-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}>
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                    </svg>
                                    <span className="mx-2 truncate w-72">
                                        New Delhi, India
                                    </span>
                                </p>
                                <p className="flex items-start -mx-2 py-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6 mx-2 text-blue-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}>
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                        />
                                    </svg>
                                    <span className="mx-2 truncate w-72">
                                        +91 9870571657
                                    </span>
                                </p>
                                <p className="flex items-start -mx-2 py-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6 mx-2 text-blue-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}>
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                        />
                                    </svg>
                                    <span className="mx-2 truncate w-72">
                                        tarotdivine208@gmail.com
                                    </span>
                                </p>
                            </div>
                            {/* <div class="mt-[6%] sm:mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div>
                                    <Link
                                        to="https://www.facebook.com/shivangichandra"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        type="button"
                                        data-twe-ripple-init
                                        data-twe-ripple-color="light"
                                        className="mb-2 flex rounded bg-[#1877F2] px-6 py-2.5 font-medium leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg">
                                        <span className="me-5 h-4 w-4">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 320 512">
                                                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S254.43 0 225.36 0C141.09 0 89.09 47.89 89.09 134.29V195.3H0v92.66h89.09V512h107.58V288z" />
                                            </svg>
                                        </span>
                                        Facebook
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        to="https://www.youtube.com/@divination777"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        type="button"
                                        data-twe-ripple-init
                                        data-twe-ripple-color="light"
                                        className="mb-2 flex rounded bg-[#ff0000] px-6 py-2.5 font-medium leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg">
                                        <span className="me-3 h-4 w-6">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 576 512">
                                                <path d="M549.655 137.045c-6.281-23.671-24.255-42.562-47.881-48.843C458.368 64 288 64 288 64S117.632 64 74.225 88.202c-23.626 6.281-41.6 25.172-47.881 48.843C8.032 180.29 8 256 8 256s.032 75.71 18.344 118.155c6.281 23.671 24.255 42.562 47.881 48.843C117.632 448 288 448 288 448s170.368 0 213.775-24.202c23.626-6.281 41.6-25.172 47.881-48.843C567.968 331.71 568 256 568 256s-.032-75.71-18.345-118.155zM232 352V160l160 96-160 96z" />
                                            </svg>
                                        </span>
                                        YouTube
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        to="/twitter"
                                        type="button"
                                        data-twe-ripple-init
                                        data-twe-ripple-color="light"
                                        className="mb-2 flex rounded bg-[#1da1f2] px-6 py-2.5 font-medium leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg">
                                        <span className="me-3 h-4 w-6">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 512 512">
                                                <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
                                            </svg>
                                        </span>
                                        Twitter
                                    </Link>
                                </div>

                                <div>
                                    <Link
                                        to="https://www.instagram.com/divine_healing222/"
                                        type="button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-twe-ripple-init
                                        data-twe-ripple-color="light"
                                        className="mb-2 flex rounded bg-gradient-to-r from-[#feda75] via-[#d62976] to-[#4f5bd5] px-6 py-2.5 font-medium leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg">
                                        <span className="me-3 h-4 w-6">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 448 512">
                                                <path d="M224.1 141c-63.6 0-115 51.4-115 115s51.4 115 115 115 115-51.4 115-115-51.4-115-115-115zm0 190c-41.3 0-75-33.7-75-75s33.7-75 75-75 75 33.7 75 75-33.7 75-75 75zm146.4-194.1c0 14.9-12 26.9-26.9 26.9s-26.9-12-26.9-26.9 12-26.9 26.9-26.9 26.9 12 26.9 26.9zm76.1 27.2c-1.7-35.7-9.9-67.4-36.2-93.7S383.8 8.4 348.1 6.7c-35.7-1.7-142.3-1.7-178 0-35.7 1.7-67.4 9.9-93.7 36.2S8.4 128.3 6.7 164c-1.7 35.7-1.7 142.3 0 178 1.7 35.7 9.9 67.4 36.2 93.7s57.9 34.5 93.7 36.2c35.7 1.7 142.3 1.7 178 0 35.7-1.7 67.4-9.9 93.7-36.2s34.5-57.9 36.2-93.7c1.7-35.7 1.7-142.3 0-178zm-48.7 214c-7.8 19.7-23 35-42.6 42.6-29.5 11.7-99.5 9-132.7 9s-103.2 2.6-132.7-9c-19.7-7.8-35-23-42.6-42.6-11.7-29.5-9-99.5-9-132.7s-2.6-103.2 9-132.7c7.8-19.7 23-35 42.6-42.6 29.5-11.7 99.5-9 132.7-9s103.2-2.6 132.7 9c19.7 7.8 35 23 42.6 42.6 11.7 29.5 9 99.5 9 132.7s2.6 103.2-9 132.7z" />
                                            </svg>
                                        </span>
                                        Instagram
                                    </Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="mt-8 lg:w-1/2 lg:mx-6">
                        <div className="w-full px-6 sm:px-8 pt-4 pb-8 mx-auto overflow-hidden bg-white rounded-lg shadow-2xl lg:max-w-xl shadow-black/50">
                            <form
                                className="mt-6"
                                action="https://formspree.io/f/xanwqjgr"
                                method="POST">
                                <div className="flex-1">
                                    <input
                                        required
                                        type="text"
                                        name="First & Last Name : "
                                        placeholder="First & Last Name *"
                                        maxLength={256}
                                        className="block w-full px-5 py-3 mt-2 text-gray-800 placeholder-gray-400 bg-white border border-gray-700 rounded-md focus:border-blue-400 focus:ring-blue-200 focus:outline-none focus:ring focus:ring-opacity-40"
                                    />
                                </div>
                                <div className="flex-1 mt-6">
                                    <input
                                        required
                                        type="email"
                                        name="Email Address : "
                                        placeholder="Email Address *"
                                        maxLength={256}
                                        className="block w-full px-5 py-3 mt-2 text-gray-800 placeholder-gray-400 bg-white border border-gray-700 rounded-md focus:border-blue-400 focus:ring-blue-200 focus:outline-none focus:ring focus:ring-opacity-40"
                                    />
                                </div>
                                <div className="flex-1 mt-6">
                                    <input
                                        required
                                        type="tel"
                                        name="Phone Number : "
                                        placeholder="Phone Number *"
                                        maxLength={256}
                                        className="block w-full px-5 py-3 mt-2 text-gray-800 placeholder-gray-400 bg-white border border-gray-700 rounded-md focus:border-blue-400 focus:ring-blue-200 focus:outline-none focus:ring focus:ring-opacity-40"
                                    />
                                </div>
                                <div className="flex-1 mt-6">
                                    <input
                                        required
                                        type="text"
                                        name="Reason for reaching out : "
                                        placeholder="Reason for reaching out *"
                                        maxLength={256}
                                        className="block w-full px-5 py-3 mt-2 text-gray-800 placeholder-gray-400 bg-white border border-gray-700 rounded-md focus:border-blue-400 focus:ring-blue-200 focus:outline-none focus:ring focus:ring-opacity-40"
                                    />
                                </div>
                                <div className="flex-1 mt-6">
                                    <textarea
                                        required
                                        className="block w-full px-5 py-3 mt-2 text-gray-800 placeholder-gray-400 bg-white border border-gray-700 rounded-md focus:border-blue-400 focus:ring-blue-200 focus:outline-none focus:ring focus:ring-opacity-40"
                                        placeholder="Tell me a bit more about why you are reaching out *"
                                        name="Tell me a bit more about why you are reaching out : "
                                        rows={6}
                                        maxLength={5000}
                                        defaultValue={""}
                                    />
                                </div>
                                <button className="w-full py-4 sm:px-6 sm:py-5 mt-6 text-lg font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#004c4c] rounded-[5rem] hover:bg-[#003737] focus:outline-none">
                                    Send Message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer
                top={top}
                testimonials={testimonials}
                services={services}
                contactMe={contactMe}
            />
        </div>
    );
};

export default Contact;
