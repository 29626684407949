import { useRef } from "react";
import Testimonial from "./Testimonial";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const AllTestimonials = ({ testimonials }) => {
    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
        }
    };

    return (
        <div ref={testimonials} className="pt-[6%] pb-[6%]">
            <div className="font-bold text-center md:text-left pb-[4%] text-2xl md:text-3xl lg:text-4xl md:ml-[9%]">
                What my Clients say
            </div>
            <div className="relative">
                <div className="flex justify-end mb-4 pr-[5%] md:pr-[8%] text-sm md:text-2xl lg:text-3xl">
                    <button
                        onClick={scrollLeft}
                        className="p-4 bg-[#004c4c] text-white rounded-full hover:bg-[#003737] mx-1">
                        <FaChevronLeft />
                    </button>
                    <button
                        onClick={scrollRight}
                        className="p-4 bg-[#004c4c] text-white rounded-full hover:bg-[#003737] mx-1">
                        <FaChevronRight />
                    </button>
                </div>
                <div
                    ref={scrollRef}
                    className="sm:mx-[9%] flex space-x-4 p-4 overflow-x-auto hide-scrollbar">
                    <Testimonial
                        headerText="Jay Chabra"
                        bodyText="I couldn't get any better eye opener than this. Few things were not just spot on but bullseye which ensures the further guidance is apt too. Loved the session. And honesty too."
                        star="⭐️⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Vikram Iyer"
                        bodyText="Such an experience with the healing process with you! Within a few hours time I could see my physical presence witnessing changes in vibrations! What an immense feeling Thankyou so much."
                        star="⭐️⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Sushant"
                        bodyText="Healing was so effective and it worked. Healing for any reason is provided by you and they are so effective. They provide quick results. You do a great job."
                        star="⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Veera Gill"
                        bodyText="I honestly got to know that the readings are on point the moment you shared about the first question. And trust me I was able to resonate with every word you said. Its gonna be a difficult path but with your guidance I am sure its gonna help me out."
                        star="⭐️⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Antra"
                        bodyText="I love you is my best review that I'll give you. Thank you so much for always calming me when I get so hyper and I keep thinking so much. And without you I don't think I would have come this long thank you so much."
                        star="⭐️⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Rajiv"
                        bodyText="It was so good to share my thoughts with you and get clarity on so many problems that were bothering me. Thank you and God bless you."
                        star="⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Nidhi"
                        bodyText="It was so peaceful talking to you. You put across things in a very simple manner that made all issues resolved."
                        star="⭐️⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Gaurav"
                        bodyText="Thanks for getting me back on track and not get driven by my emotions for worst case scenarios."
                        star="⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Meenakshi"
                        bodyText="Thank you so much for that reading. Things turned out successfully as per your predictions. My brother and Dad finally came back just now after 2.5 months."
                        star="⭐️⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Shiv Kapoor"
                        bodyText="I don't believe in tarot reading but to my surprise whatever you said that day was so accurate I mean you gave me some specific dates and I still wonder how is that even possible. It was such a wonderful experience."
                        star="⭐️⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Sanah"
                        bodyText="Thank you for an excellent reading Shivangi. It was bang on the point and gave me so much clarity and positivity. Your vibe is so positive and infectious. Thank you one again."
                        star="⭐️⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Kartik"
                        bodyText="Thanks for being approachable and explaining things in a clear manner with great examples from everyday life. Your reading of my current situation was bang on. Thank you for taking time to explain in detail the cards I had drawn and for being gentle and not rushing me through the session. You guided me well."
                        star="⭐️⭐️⭐️⭐️⭐️"
                    />
                    <Testimonial
                        headerText="Roman Stroganov"
                        bodyText="It was so true, I wondered how you could possibly know. It was as if you were reading my mind and my surroundings. Thank you so much for your guidance."
                        star="⭐️⭐️⭐️⭐️⭐️"
                    />
                </div>
            </div>
        </div>
    );
};

export default AllTestimonials;
