import { useNavigate } from "react-router-dom";

const Homepage = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/");
    };

    return (
        <div>
            <button
                className="py-2 mb-12 px-6 sm:py-4 text-lg font-medium tracking-wide text-white transition-colors duration-300 transform bg-[#004c4c] rounded-[5rem] hover:bg-[#003737] focus:outline-none"
                onClick={handleClick}>
                Back to Homepage
            </button>
        </div>
    );
};

export default Homepage;
