import Logo from "../images/logo.jpg";
import { Link } from "react-router-dom";

const Footer = ({ top, testimonials, services, contactMe }) => {
    const scrollToSection = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <footer>
            <div className="px-[6%] lg:px-[2%] lg:mx-auto w-full max-w-screen-xl pt-20 pb-16 lg:py-8">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <Link
                            className="self-center text-2xl font-semibold whitespace-nowrap flex items-center"
                            to="/"
                            onClick={() => scrollToSection(top)}>
                            <img
                                src={Logo}
                                alt="DivineReadings"
                                className="w-8 h-8 md:w-10 md:h-10 mr-2 rounded"
                            />
                            DivineReadings
                        </Link>
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                        <div>
                            <h2 className="mb-4 md:mb-6 text-xl font-extrabold text-gray-900">
                                Company
                            </h2>
                            <ul className="text-gray-500 font-medium">
                                <li className="mb-2 md:mb-4">
                                    <Link
                                        to="/services"
                                        onClick={() =>
                                            scrollToSection(services)
                                        }
                                        className="hover:underline">
                                        Services
                                    </Link>
                                </li>
                                <li className="mb-2 md:mb-4">
                                    <Link
                                        to="/testimonials"
                                        onClick={() =>
                                            scrollToSection(testimonials)
                                        }
                                        className="hover:underline">
                                        Testimonials
                                    </Link>
                                </li>
                                <li className="mb-2 md:mb-4">
                                    <Link
                                        to="/contact"
                                        onClick={() =>
                                            scrollToSection(contactMe)
                                        }
                                        className="hover:underline">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-4 md:mb-6 text-xl font-extrabold text-gray-900">
                                Follow Me
                            </h2>
                            <ul className="text-gray-500 font-medium">
                                <li className="mb-2 md:mb-4">
                                    <Link
                                        to="https://www.instagram.com/divine_healing222/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="hover:underline">
                                        Instagram
                                    </Link>
                                </li>
                                <li className="mb-2 md:mb-4">
                                    <Link
                                        to="https://www.youtube.com/@divination777"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="hover:underline">
                                        Youtube
                                    </Link>
                                </li>
                                <li className="mb-2 md:mb-4">
                                    <Link
                                        to="https://www.facebook.com/shivangichandra"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="hover:underline">
                                        Facebook
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-4 md:mb-6 text-xl font-extrabold text-gray-900">
                                Legal
                            </h2>
                            <ul className="text-gray-500 font-medium">
                                <li className="mb-2 md:mb-4">
                                    <Link
                                        to="/privacy-policy"
                                        className="hover:underline">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li className="mb-2 md:mb-4">
                                    <Link
                                        to="/terms-and-conditions"
                                        className="hover:underline">
                                        Terms & Conditions
                                    </Link>
                                </li>
                                <li className="mb-2 md:mb-4">
                                    <Link
                                        to="/refunds-policy"
                                        className="hover:underline">
                                        Refunds Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
                <div className="sm:flex sm:items-center sm:justify-between text-center sm:text-inherit">
                    <span
                        className="text-lg sm:text-center cursor-pointer"
                        onClick={() => scrollToSection(top)}>
                        ©️ Copyright DivineReadings 2024. All Rights Reserved
                    </span>
                    <div className="flex mt-4 justify-center sm:mt-0">
                        <Link
                            className="mt-2 sm:mt-0 mr-4 transition-colors duration-300 transform text-gray-800 hover:text-black"
                            to="https://www.instagram.com/divine_healing222/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className="[&>svg]:h-5 [&>svg]:w-5">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 448 512">
                                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                </svg>
                            </span>
                        </Link>
                        <Link
                            className="mt-2 sm:mt-0 mx-4 transition-colors duration-300 transform text-gray-800 hover:text-black"
                            to="https://www.youtube.com/@divination777"
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className="[&>svg]:h-5 [&>svg]:w-5">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 576 512">
                                    <path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
                                </svg>
                            </span>
                        </Link>
                        <Link
                            className="mt-2 sm:mt-0 ml-4 transition-colors duration-300 transform text-gray-800 hover:text-black"
                            to="https://www.facebook.com/shivangichandra"
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className="[&>svg]:h-5 [&>svg]:w-5">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 320 512">
                                    <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                                </svg>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
