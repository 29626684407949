import { useEffect } from "react";
import Homepage from "./Homepage";

const RefundsPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="text-justify mt-[4%] w-4/5 m-auto text-lg">
            <p className="text-center text-xl md:text-2xl font-bold mb-8">
                Refunds Policy
            </p>
            <p>
                At DivineReadings, we strive to provide a high-quality tarot
                reading service. However, please be aware that all bookings made
                with us are non-refundable. Once you have booked a session, we
                are unable to offer any refunds.
            </p>
            <p className="mt-8">
                We understand that circumstances may change, and you may have
                unforeseen situations that prevent you from attending the
                scheduled session. However, please note that we are unable to
                accommodate refund requests, regardless of the reason.
            </p>
            <p className="mt-8">
                We encourage you to carefully consider your availability and
                commitment before making a booking. If you have any doubts or
                questions about our services, we are more than happy to address
                them prior to your booking. You can contact us via email at
                shivangichandra@gmail.com or through WhatsApp at the provided
                number.
            </p>
            <p className="mt-8">
                We appreciate your understanding and cooperation in adhering to
                our refund policy. We are committed to providing you with
                enriching and insightful services, and we look forward to
                assisting you on your journey.
            </p>
            <p className="mt-8 mb-12">Thank you for choosing DivineReadings!</p>
            <Homepage />
        </div>
    );
};

export default RefundsPolicy;
