import { Link } from "react-router-dom";

const Button = ({ width, buttonScroll }) => {
    const handleButtonClick = () => {
        window.scrollTo({
            top: buttonScroll.current.offsetTop,
            behavior: "smooth",
        });
    };
    return (
        <Link>
            <button
                className={`${width} py-3 px-4 sm:px-6 sm:py-5 mt-6 sm:text-lg font-medium tracking-wide text-white transition-colors duration-300 transform bg-[#004c4c] rounded-[5rem] hover:bg-[#003737] focus:outline-none`}
                onClick={handleButtonClick}>
                Book an Appointment
            </button>
        </Link>
    );
};

export default Button;
