import { Link } from "react-router-dom";
import React, { useState } from "react";
import Payment from "../images/payment.jpeg";

const PaymentDetailsPopup = ({ closePopup }) => {
    const [copied, setCopied] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleCopy = () => {
        const upiId = "9870571657@ptyes";
        navigator.clipboard.writeText(upiId).then(() => {
            setCopied(true);
            setTooltipVisible(true);
            setTimeout(() => {
                setCopied(false);
                setTooltipVisible(false);
            }, 2000);
        });
    };

    // Handle tooltip visibility on touch devices
    const handleTouchStart = () => {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 2000);
    };

    return (
        <div className="z-50 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-60">
            <div className="bg-white rounded-lg p-6 shadow-md max-w-md">
                <img
                    src={Payment}
                    alt="payment-id"
                    className="w-full rounded-lg"
                />
                <div className="w-full max-w-sm mt-4">
                    <div className="flex items-center relative">
                        <span className="flex-shrink-0 z-10 inline-flex items-center py-3 px-4 font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg">
                            UPI ID
                        </span>
                        <div className="relative w-full">
                            <div
                                id="upi-id"
                                className="bg-gray-50 border border-e-0 border-gray-300 border-s-0 block w-full p-3">
                                9870571657@ptyes
                            </div>
                        </div>
                        <div className="relative">
                            <button
                                onClick={handleCopy}
                                onTouchStart={handleTouchStart}
                                onMouseEnter={() => setTooltipVisible(true)}
                                onMouseLeave={() => setTooltipVisible(false)}
                                className="relative flex-shrink-0 z-10 text-lg inline-flex items-center py-4 px-4 font-medium text-center text-white bg-blue-700 rounded-e-lg hover:bg-blue-800 focus:outline-none border border-blue-700 hover:border-blue-800"
                                type="button">
                                {copied ? (
                                    <svg
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 12">
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M1 5.917 5.724 10.5 15 1.5"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 18 20">
                                        <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                                    </svg>
                                )}
                            </button>
                            {tooltipVisible && (
                                <div
                                    className={`absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 z-20 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-100 transition-opacity duration-300 whitespace-nowrap`}>
                                    {copied ? "Copied!" : "Copy UPI ID"}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-4 text-center">
                    <div className="text-center">
                        <Link
                            to="https://calendly.com/tarotdivine208/30min"
                            target="_blank"
                            rel="noopener noreferrer">
                            <button className="px-6 py-2.5 sm:text-lg font-medium tracking-wide text-white transition-colors duration-300 transform bg-[#004c4c] rounded-[5rem] hover:bg-[#003737] focus:outline-none">
                                Book an Appointment
                            </button>
                        </Link>
                    </div>
                </div>
                <button
                    onClick={closePopup}
                    className="block mx-auto mt-4 px-6 py-2.5 bg-[#004c4c] text-white rounded-lg focus:outline-none hover:bg-[#003737] transition-colors duration-300">
                    Close
                </button>
            </div>
        </div>
    );
};

// const PaymentDetailsPopup = ({ closePopup }) => {
//     const [copied, setCopied] = useState(false);
//     const [hovered, setHovered] = useState(false);

//     const handleCopy = () => {
//         const upiId = "9870571657@ptyes";
//         navigator.clipboard.writeText(upiId).then(() => {
//             setCopied(true);
//             setTimeout(() => setCopied(false), 2000);
//         });
//     };

//     return (
//         <div className="z-50 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-60">
//             <div className="bg-white rounded-lg p-6 shadow-md max-w-md">
//                 <img
//                     src={Payment}
//                     alt="payment-id"
//                     className="w-full rounded-lg"
//                 />
//                 <div className="w-full max-w-sm mt-4">
//                     <div className="flex items-center relative">
//                         <span className="flex-shrink-0 z-10 inline-flex items-center py-3 px-4 font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg">
//                             UPI ID
//                         </span>
//                         <div className="relative w-full">
//                             <div
//                                 id="upi-id"
//                                 className="bg-gray-50 border border-e-0 border-gray-300 border-s-0 block w-full p-3">
//                                 9870571657@ptyes
//                             </div>
//                         </div>
//                         <div className="relative">
//                             <button
//                                 onClick={handleCopy}
//                                 onMouseEnter={() => setHovered(true)}
//                                 onMouseLeave={() => setHovered(false)}
//                                 className="relative flex-shrink-0 z-10 text-lg inline-flex items-center py-4 px-4 font-medium text-center text-white bg-blue-700 rounded-e-lg hover:bg-blue-800 focus:outline-none border border-blue-700 hover:border-blue-800"
//                                 type="button">
//                                 {copied ? (
//                                     <svg
//                                         className="w-4 h-4"
//                                         aria-hidden="true"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         fill="none"
//                                         viewBox="0 0 16 12">
//                                         <path
//                                             stroke="currentColor"
//                                             strokeLinecap="round"
//                                             strokeLinejoin="round"
//                                             strokeWidth={2}
//                                             d="M1 5.917 5.724 10.5 15 1.5"
//                                         />
//                                     </svg>
//                                 ) : (
//                                     <svg
//                                         className="w-4 h-4"
//                                         aria-hidden="true"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         fill="currentColor"
//                                         viewBox="0 0 18 20">
//                                         <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
//                                     </svg>
//                                 )}
//                             </button>
//                             {(hovered || copied) && (
//                                 <div
//                                     className={`absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 z-20 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-100 transition-opacity duration-300 whitespace-nowrap`}>
//                                     {copied ? "Copied!" : "Copy UPI ID"}
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//                 <div className="mt-4 text-center">
//                     <a
//                         href="https://calendly.com/shivangichandra/1hour"
//                         target="_blank"
//                         rel="noopener noreferrer">
//                         <div className="text-center">
//                             <button className="text-sm px-6 py-2 sm:text-lg font-medium tracking-wide text-white transition-colors duration-300 transform bg-[#004c4c] rounded-[5rem] hover:bg-[#003737] focus:outline-none">
//                                 Book an Appointment
//                             </button>
//                         </div>
//                     </a>
//                 </div>
//                 <button
//                     onClick={closePopup}
//                     className="block mx-auto mt-4 px-6 py-3 bg-[#004c4c] text-white rounded-lg focus:outline-none hover:bg-[#003737] transition-colors duration-300">
//                     Close
//                 </button>
//             </div>
//         </div>
//     );
// };

const Booking = ({ services, buttonScroll }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <div ref={buttonScroll}>
            <div ref={services}>
                <div className="container m-auto pt-6 lg:pt-0">
                    <h2 className="pt-[2%] pb-[4%] text-2xl md:text-3xl lg:text-4xl font-bold text-center">
                        Schedule a Meeting with me
                    </h2>
                    <div className="pb-[4%] flex flex-wrap items-center text-center">
                        <div className="mx-[6%] w-full sm:w-3/4 sm:mx-auto sm:px-4">
                            <table className="min-w-full bg-white border border-gray-300 border-collapse">
                                <thead>
                                    <tr className="bg-gray-100 border-b border-gray-300">
                                        <th className="w-1/5 py-2 px-4 font-bold text-lg border-r border-gray-300">
                                            No.
                                        </th>
                                        <th className="w-3/5 py-2 px-4 font-bold text-lg border-r border-gray-300">
                                            Basic Services
                                        </th>
                                        <th className="w-1/5 py-2 px-4 font-bold text-lg">
                                            Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            1
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Customized switchwords
                                        </td>
                                        <td className="py-2 px-4">INR 555</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            2
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Relationship improvement reiki
                                        </td>
                                        <td className="py-2 px-4">INR 999</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            3
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Aura Healing
                                        </td>
                                        <td className="py-2 px-4">INR 1000</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            4
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Career growth reiki
                                        </td>
                                        <td className="py-2 px-4">INR 1111</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            5
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Bay Leaf Ritual for Luck
                                        </td>
                                        <td className="py-2 px-4">INR 1111</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            6
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Balancing emotions reiki
                                        </td>
                                        <td className="py-2 px-4">INR 2222</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            7
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Chakra cleansing
                                        </td>
                                        <td className="py-2 px-4">INR 3333</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            8
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Cord cutting from toxicity
                                        </td>
                                        <td className="py-2 px-4">INR 5000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-[6%] sm:mt-[4%] md:mt-[2%] mx-[6%] w-full sm:w-3/4 sm:mx-auto sm:px-4">
                            <table className="min-w-full bg-white border border-gray-300 border-collapse">
                                <thead>
                                    <tr className="bg-gray-100 border-b border-gray-300">
                                        <th className="w-1/5 py-2 px-4 font-bold text-lg border-r border-gray-300">
                                            No.
                                        </th>
                                        <th className="w-3/5 py-2 px-4 font-bold text-lg border-r border-gray-300">
                                            Intermediate Services
                                        </th>
                                        <th className="w-1/5 py-2 px-4 font-bold text-lg">
                                            Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            1
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Face reading
                                        </td>
                                        <td className="py-2 px-4">INR 2000</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            2
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Coffee Cup Readings
                                        </td>
                                        <td className="py-2 px-4">INR 3500</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            3
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Aakashic records readings
                                        </td>
                                        <td className="py-2 px-4">INR 5000</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            4
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Unlimited questions for 1 hr
                                        </td>
                                        <td className="py-2 px-4">INR 6000</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            5
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Psychic medium readings
                                        </td>
                                        <td className="py-2 px-4">INR 8000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-[6%] sm:mt-[4%] md:mt-[2%] mx-[6%] w-full sm:w-3/4 sm:mx-auto sm:px-4">
                            <table className="min-w-full bg-white border border-gray-300 border-collapse">
                                <thead>
                                    <tr className="bg-gray-100 border-b border-gray-300">
                                        <th className="w-1/5 py-2 px-4 font-bold text-lg border-r border-gray-300">
                                            No.
                                        </th>
                                        <th className="w-3/5 py-2 px-4 font-bold text-lg border-r border-gray-300">
                                            Advanced Services
                                        </th>
                                        <th className="w-1/5 py-2 px-4 font-bold text-lg">
                                            Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            1
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Dream Interpretation
                                        </td>
                                        <td className="py-2 px-4">INR 2000</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            2
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Removing Nazar/Hexes/Curses
                                        </td>
                                        <td className="py-2 px-4">INR 3000</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            3
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Manifestation techniques 1 hr
                                            session
                                        </td>
                                        <td className="py-2 px-4">INR 5555</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            4
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Reprogramming mind 7 days online
                                            session
                                        </td>
                                        <td className="py-2 px-4">INR 7777</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            5
                                        </td>
                                        <td className="py-2 px-4 border-r border-gray-300">
                                            Spiritual Journey Guidance
                                        </td>
                                        <td className="py-2 px-4">INR 10000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="min-h-[450px] w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
                            <div className="rounded-lg shadow-2xl shadow-black/50 bg-white pb-4 h-full flex flex-col justify-between">
                                <div className="bg-[#004c4c] rounded-t-lg">
                                    <h3 className="pt-[4%] text-2xl text-center font-semibold mb-4 text-white">
                                        Basic
                                    </h3>
                                </div>
                                <div className="mx-[5%] pt-[10%] flex flex-col gap-6 sm:gap-8">
                                    <p className="flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>INR 1000 Aura Healing</b>
                                    </p>
                                    <p className="flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>INR 1111 Bay Leaf Ritual for Luck</b>
                                    </p>
                                    <p className="invisible flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>INR 1111 Bay Leaf Ritual for Luck</b>
                                    </p>
                                    <p className="invisible flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>INR 1111 Bay Leaf Ritual for Luck</b>
                                    </p>
                                    <b className="invisible hidden lg:inline">
                                        INR 2000 Dream Interpretation
                                    </b>
                                    <br className="hidden lg:block" />
                                </div>
                                <div className="pb-[4%] text-center mt-6">
                                    <button
                                        onClick={togglePopup}
                                        className="px-10 py-4 text-lg font-medium tracking-wide text-white transition-colors duration-300 transform bg-[#004c4c] rounded-[5rem] hover:bg-[#003737] focus:outline-none">
                                        Book an Appointment
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="min-h-[450px] w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
                            <div className="rounded-lg shadow-2xl shadow-black/50 bg-white pb-4 h-full flex flex-col justify-between">
                                <div className="bg-[#004c4c] rounded-t-lg">
                                    <h3 className="pt-[4%] text-2xl text-center font-semibold mb-4 text-white">
                                        Intermediate
                                    </h3>
                                </div>
                                <div className="mx-[5%] pt-[10%] flex flex-col gap-6 sm:gap-8">
                                    <p className="flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>INR 6000 1hr unlimited questions</b>
                                    </p>
                                    <p className="flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>
                                            INR 3000 30min unlimited questions
                                        </b>
                                    </p>
                                    <p className="invisible flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>INR 500 Per Question Basis</b>
                                    </p>
                                    <p className="invisible flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>INR 500 Per Question Basis</b>
                                    </p>
                                    <b className="invisible hidden lg:inline">
                                        INR 2000 Dream Interpretation
                                    </b>
                                    <br className="hidden lg:block" />
                                </div>
                                <div className="pb-[4%] text-center mt-6">
                                    <button
                                        onClick={togglePopup}
                                        className="px-10 py-4 text-lg font-medium tracking-wide text-white transition-colors duration-300 transform bg-[#004c4c] rounded-[5rem] hover:bg-[#003737] focus:outline-none">
                                        Book an Appointment
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="min-h-[450px] w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
                            <div className="rounded-lg shadow-2xl shadow-black/50 bg-white pb-4 h-full flex flex-col justify-between">
                                <div className="bg-[#004c4c] rounded-t-lg">
                                    <h3 className="pt-[4%] text-2xl text-center font-semibold mb-4 text-white">
                                        Advance
                                    </h3>
                                </div>
                                <div className="mx-[5%] pt-[10%] flex flex-col gap-6 sm:gap-8">
                                    <p className="flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>
                                            INR 10000 Spiritual Journey Guidance
                                        </b>
                                    </p>
                                    <p className="flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>
                                            INR 3000 Removing Nazar/Hexes/Curses
                                        </b>
                                    </p>
                                    <p className="flex text-lg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="w-6 h-4 mr-2 mt-[2%]"
                                            stroke="currentColor"
                                            strokeWidth="2">
                                            <path
                                                fillRule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <b>INR 2000 Dream Interpretation</b>
                                    </p>
                                    <b className="invisible">
                                        INR 2000 Dream Interpretation
                                    </b>
                                    <br />
                                </div>
                                <div className="pb-[4%] text-center mt-6">
                                    <button
                                        onClick={togglePopup}
                                        className="px-10 py-4 text-lg font-medium tracking-wide text-white transition-colors duration-300 transform bg-[#004c4c] rounded-[5rem] hover:bg-[#003737] focus:outline-none">
                                        Book an Appointment
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="w-[65%] sm:w-3/5 m-auto px-4 mb-6">
                        <div className="pb-[4%] text-center mt-2">
                            <button
                                onClick={togglePopup}
                                className="w-full sm:w-4/5 lg:w-1/2 py-3 sm:px-6 sm:py-5 mt-6 sm:text-lg font-medium tracking-wide text-white transition-colors duration-300 transform bg-[#004c4c] rounded-[5rem] hover:bg-[#003737] focus:outline-none">
                                Book an Appointment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isPopupOpen && <PaymentDetailsPopup closePopup={togglePopup} />}
        </div>
    );
};

export default Booking;
