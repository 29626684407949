import App from "../App";
import Body from "../components/Body";
import { createBrowserRouter } from "react-router-dom";
import PrivacyPolicy from "../components/PrivacyPolicy";
import RefundsPolicy from "../components/RefundsPolicy";
import TermsConditions from "../components/TermsConditions";

const appRouter = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <Body />,
            },
            {
                path: "/know",
                element: <Body />,
            },
            {
                path: "/testimonials",
                element: <Body />,
            },
            {
                path: "/services",
                element: <Body />,
            },
            {
                path: "/contact",
                element: <Body />,
            },
            {
                path: "/privacy-policy",
                element: <PrivacyPolicy />,
            },
            {
                path: "/terms-and-conditions",
                element: <TermsConditions />,
            },
            {
                path: "/refunds-policy",
                element: <RefundsPolicy />,
            },
        ],
    },
]);

export default appRouter;
