import { useEffect } from "react";
import Homepage from "./Homepage";

const TermsConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="text-justify mt-[4%] w-4/5 m-auto text-lg">
            <p className="text-center text-xl md:text-2xl font-bold mb-8">
                Terms and Conditions
            </p>
            <p className="mt-8">Welcome to DivineReadings!</p>
            <p className="mt-8">
                These terms and conditions outline the rules and regulations for
                the use of DivineReadings Website, located at
                https://divinereadings.co/
            </p>
            <p className="mt-8">
                By accessing this website, we assume you accept these terms and
                conditions. Do not continue to use DivineReadings or it's
                services if you do not agree to take all of the terms and
                conditions stated on this page.
            </p>
            <p className="font-bold text-lg mt-8">Cookies:</p>
            <p>
                The website uses cookies to help personalize your online
                experience. By accessing DivineReadings, you agreed to use the
                required cookies.
            </p>
            <p className="mt-8">
                A cookie is a text file that is placed on your hard disk by a
                web page server. Cookies cannot be used to run programs or
                deliver viruses to your computer. Cookies are uniquely assigned
                to you and can only be read by a web server in the domain that
                issued the cookie to you.
            </p>
            <p className="mt-8">
                We may use cookies to collect, store, and track information for
                statistical or marketing purposes to operate our website. You
                have the ability to accept or decline optional Cookies. There
                are some required Cookies that are necessary for the operation
                of our website. These cookies do not require your consent as
                they always work. Please keep in mind that by accepting required
                Cookies, you also accept third-party Cookies, which might be
                used via third-party provided services if you use such services
                on our website, for example, a video display window provided by
                third parties and integrated into our website.
            </p>
            <p className="font-bold text-lg mt-8">License:</p>
            <p>
                Unless otherwise stated, DivineReadings and/or its licensors own
                the intellectual property rights for all material on
                DivineReadings or it's services. All intellectual property
                rights are reserved. You may access this from DivineReadings for
                your own personal use subjected to restrictions set in these
                terms and conditions.
            </p>
            <p className="mt-8">You must not:</p>
            <p>
                Copy or republish material from DivineReadings or it's services{" "}
            </p>
            <p>Sell, rent, or sub-license material from DivineReadings </p>
            <p>
                Reproduce, duplicate or copy material from DivineReadings or
                it's services
            </p>
            <p>Redistribute content from DivineReadings</p>
            <p>This Agreement shall begin on the date hereof</p>
            <p className="mt-8">
                Parts of this website offer users an opportunity to post and
                exchange opinions and information in certain areas of the
                website. DivineReadings does not filter, edit, publish or review
                Comments before their presence on the website. Comments do not
                reflect the views and opinions of DivineReadings, its agents,
                and/or affiliates. Comments reflect the views and opinions of
                the person who posts their views and opinions. To the extent
                permitted by applicable laws, DivineReadings shall not be liable
                for the Comments or any liability, damages, or expenses caused
                and/or suffered as a result of any use of and/or posting of
                and/or appearance of the Comments on this website.
            </p>
            <p className="mt-8">
                DivineReadings reserves the right to monitor all Comments and
                remove any Comments that can be considered inappropriate,
                offensive, or causes breach of these Terms and Conditions.
            </p>
            <p className="mt-8">You warrant and represent that:</p>
            <p>
                You are entitled to post the Comments on our website and have
                all necessary licenses and consents to do so.
            </p>
            <p>
                The Comments do not invade any intellectual property right,
                including without limitation copyright, patent, or trademark of
                any third party.
            </p>
            <p>
                The Comments do not contain any defamatory, libelous, offensive,
                indecent, or otherwise unlawful material, which is an invasion
                of privacy.
            </p>
            <p>
                The Comments will not be used to solicit or promote business or
                custom or present commercial activities or unlawful activity.
            </p>
            <p>
                You hereby grant DivineReadings a non-exclusive license to use,
                reproduce, edit and authorize others to use, reproduce and edit
                any of your Comments in any and all forms, formats, or media.
            </p>
            <p className="font-bold text-lg mt-8">
                Hyperlinking to our Content:
            </p>
            <p>
                The following organizations may link to our Website without
                prior written approval:
            </p>
            <p>Government agencies;</p>
            <p>Search engines;</p>
            <p>News organizations;</p>
            <p>
                Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses; and
            </p>
            <p>
                System-wide Accredited Businesses except soliciting non-profit
                organizations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Web site.
            </p>
            <p className="mt-8">
                These organizations may link to our home page, to publications,
                or to other Website information so long as the link: (a) is not
                in any way deceptive; (b) does not falsely imply sponsorship,
                endorsement, or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking
                party's site.
            </p>
            <p className="mt-8 font-bold text-lg">
                We may consider and approve other link requests from the
                following types of organizations:
            </p>
            <p>Commonly-known consumer and/or business information sources;</p>
            <p>Dot.com community sites;</p>
            <p>Associations or other groups representing charities;</p>
            <p>Online directory distributors;</p>
            <p>Internet portals;</p>
            <p>Accounting, law, and consulting firms; and</p>
            <p>Educational institutions and trade associations</p>
            <p className="mt-8">
                We will approve link requests from these organizations if we
                decide that: (a) the link would not make us look unfavorably to
                ourselves or to our accredited businesses; (b) the organization
                does not have any negative records with us; (c) the benefit to
                us from the visibility of the hyperlink compensates the absence
                of Tarot With Lavanya; and (d) the link is in the context of
                general resource information.
            </p>
            <p className="mt-8">
                These organizations may link to our home page so long as the
                link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement, or approval of the linking party
                and its products or services; and (c) fits within the context of
                the linking party's site.
            </p>
            <p className="mt-8">
                If you are one of the organizations listed in paragraph 2 above
                and are interested in linking to our website, you must inform us
                by sending an e-mail to DivineReadings. Please include your
                name, your organization name, contact information as well as the
                URL of your site, a list of any URLs from which you intend to
                link to our Website, and a list of the URLs on our site to which
                you would like to link. Wait 2-3 weeks for a response.
            </p>
            <p className="mt-8 font-bold text-lg">
                Approved organizations may hyperlink to our Website as follows:
            </p>
            <p>By use of our corporate name; or</p>
            <p>By use of the uniform resource locator being linked to; or</p>
            <p>
                Using any other description of our Website being linked to that
                makes sense within the context and format of content on the
                linking party's site.
            </p>
            <p>
                No use of DivineReadings or other artwork will be allowed for
                linking absent a trademark license agreement.
            </p>
            <p className="mt-8 font-bold text-lg">Content Liability:</p>
            <p>
                We shall not be held responsible for any content that appears on
                your Website. You agree to protect and defend us against all
                claims that are raised on your Website. No link(s) should appear
                on any Website that may be interpreted as libelous, obscene, or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
            </p>
            <p className="mt-8 font-bold text-lg">Reservation of Rights:</p>
            <p>
                We reserve the right to request that you remove all links or any
                particular link to our Website. You approve to immediately
                remove all links to our Website upon request. We also reserve
                the right to amend these terms and conditions and its linking
                policy at any time. By continuously linking to our Website, you
                agree to be bound to and follow these linking terms and
                conditions.
            </p>
            <p className="mt-8 font-bold text-lg">
                Removal of links from our website:
            </p>
            <p>
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us at any moment. We
                will consider requests to remove links, but we are not obligated
                to or so or to respond to you directly.
            </p>
            <p className="mt-8">
                We do not ensure that the information on this website is
                correct. We do not warrant its completeness or accuracy, nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
            </p>
            <p className="mt-8 font-bold text-lg">Disclaimer:</p>
            <p>
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties, and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
            </p>
            <p>
                Limit or exclude our or your liability for death or personal
                injury;
            </p>
            <p>
                Limit or exclude our or your liability for fraud or fraudulent
                misrepresentation;
            </p>
            <p>
                Limit any of our or your liabilities in any way that is not
                permitted under applicable law; or
            </p>
            <p>
                Exclude any of our or your liabilities that may not be excluded
                under applicable law.
            </p>
            <p>
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort, and for breach of statutory duty.
            </p>
            <p className="mb-12">
                As long as the website and the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
            </p>
            <Homepage />
        </div>
    );
};

export default TermsConditions;
