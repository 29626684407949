import { useEffect } from "react";
import Homepage from "./Homepage";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="text-justify mt-[4%] w-4/5 m-auto text-lg">
            <p className="text-center text-xl md:text-2xl font-bold mb-8">
                Privacy Policy
            </p>
            <p className="font-bold text-lg">
                DivineReadings | All services of this website are owned by
                Shivangi Chandra, who is the data controller of your personal
                data.
            </p>
            <p className="mt-8">
                We have adopted this Privacy Policy, which outlines how we
                process the information collected on this website and provides
                the reasons why we need to collect certain personal data about
                you. Therefore, it is important that you read this Privacy
                Policy before using DivineReadings.
            </p>
            <p className="mt-8">
                We take care of your personal data and are committed to
                guaranteeing its confidentiality and security.
            </p>
            <p className="mt-8 font-bold">Personal information we collect:</p>
            <p>
                When you visit DivineReadings, we automatically collect certain
                information about your device, including your web browser, IP
                address, time zone, and some of the cookies installed on your
                device. Additionally, as you browse the Site, we collect
                information about the individual web pages or products you view,
                the websites or search terms that referred you to the Site, and
                how you interact with the Site. We refer to this automatically
                collected information as “Device Information.” Moreover, we
                might collect the personal data you provide to us (including,
                but not limited to, name, surname, address, payment information,
                etc.) during registration to fulfill the agreement.
            </p>
            <p className="mt-8 font-bold">Why do we process your data?</p>
            <p>
                Our top priority is customer data security, and as such, we may
                process only minimal user data—only as much as is absolutely
                necessary to maintain the website. Information collected
                automatically is used only to identify potential cases of abuse
                and to establish statistical information regarding website
                usage. This statistical information is not otherwise aggregated
                in such a way that it would identify any particular user of the
                system.
            </p>
            <p className="mt-8">
                You can visit the website without telling us who you are or
                revealing any information that could identify you as a specific,
                identifiable individual. However, if you wish to use some of the
                website's features, receive our newsletter, or provide other
                details by filling out a form, you may provide personal data to
                us, such as your email, first name, last name, city of
                residence, organization, and telephone number. You can choose
                not to provide us with your personal data, but then you may not
                be able to take advantage of some of the website's features. For
                example, you won't be able to receive our newsletter or contact
                us directly from the website. Users who are uncertain about what
                information is mandatory are welcome to contact us via
                shivangichandra@gmail.com.
            </p>
            <p className="mt-8 font-bold">Your rights:</p>
            <p>
                If you are a European resident, you have the following rights
                related to your personal data:
            </p>
            <div className="mt-4">
                <ul className="list-disc pl-10">
                    <li className="pl-2">The right to be informed</li>
                    <li className="pl-2">The right of access</li>
                    <li className="pl-2">The right to rectification</li>
                    <li className="pl-2">The right to erasure</li>
                    <li className="pl-2">The right to restrict processing</li>
                    <li className="pl-2">The right to data portability</li>
                    <li className="pl-2">The right to object</li>
                    <li className="pl-2">
                        Rights in relation to automated decision-making and
                        profiling
                    </li>
                </ul>
            </div>

            <p className="mt-8">
                If you would like to exercise these rights, please contact us
                through the contact information provided below.
            </p>
            <p className="mt-8">
                Additionally, if you are a European resident, we note that we
                are processing your information to fulfill contracts we might
                have with you (for example, if you make an order through the
                Site), or otherwise to pursue our legitimate business interests
                listed above. Additionally, please note that your information
                might be transferred outside of Europe, including to Canada and
                the United States.
            </p>
            <p className="mt-8 font-bold">Links to other websites:</p>
            <p>
                Our website may contain links to other websites that are not
                owned or controlled by us. Please be aware that we are not
                responsible for the privacy practices of such other websites or
                third parties. We encourage you to be aware when you leave our
                website and read the privacy statements of each website that may
                collect personal information.
            </p>
            <p className="mt-8 font-bold">Information security:</p>
            <p>
                We secure information you provide on computer servers in a
                controlled, secure environment, protected from unauthorized
                access, use, or disclosure. We maintain reasonable
                administrative, technical, and physical safeguards to protect
                against unauthorized access, use, modification, and disclosure
                of personal data in our control and custody. However, no data
                transmission over the Internet or wireless network can be
                guaranteed.
            </p>
            <p className="mt-8 font-bold">Legal disclosure:</p>
            <p>
                We will disclose any information we collect, use, or receive if
                required or permitted by law, such as to comply with a subpoena
                or similar legal process, and when we believe in good faith that
                disclosure is necessary to protect our rights, your safety or
                the safety of others, to investigate fraud, or to respond to a
                government request.
            </p>
            <p className="mt-8 font-bold">Contact information:</p>
            <p className="mb-12">
                If you would like to contact us to understand more about this
                Policy or wish to contact us concerning any matter related to
                individual rights and your Personal Information, you may send an
                email to shivangichandra@gmail.com.
            </p>
            <Homepage />
        </div>
    );
};

export default PrivacyPolicy;
