import { useRef, useEffect } from "react";
import Contact from "./Contact";
import About from "./About";
import Appointment from "./Appointment";
import FAQSection from "./FAQSection";
import Reviews from "./Reviews";
import Booking from "./Booking";
import Header from "./Header";
import Introduction from "./Introduction";
import AllTestimonials from "./AllTestimonials";

const Body = () => {
    const home = useRef();
    const knowMe = useRef();
    const testimonials = useRef();
    const services = useRef();
    const contactMe = useRef();
    const buttonScroll = useRef();
    const top = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div ref={top}>
            <Header
                home={home}
                knowMe={knowMe}
                testimonials={testimonials}
                services={services}
                contactMe={contactMe}
            />
            <Introduction home={home} buttonScroll={buttonScroll} />
            <Reviews buttonScroll={buttonScroll} knowMe={knowMe} />
            <About />
            <Booking services={services} buttonScroll={buttonScroll} />
            <Appointment buttonScroll={buttonScroll} />
            <AllTestimonials testimonials={testimonials} />
            <FAQSection />
            <Contact
                top={top}
                testimonials={testimonials}
                services={services}
                contactMe={contactMe}
            />
        </div>
    );
};

export default Body;
